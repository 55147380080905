import Send from '../../trans.client.js'

export default {
  insertTemplateSaveData (params) {
    return Send({
      // + formData.bkgNo,
      // api 하위 주소 base url을 제외한 리소스명(URI) 추후 수정
      method: 'post',
      url: '/trans/bookings/template-save',
      data: params
    })
  }
}
