<template>
  <div class="popup_wrap" style="width:500px;">
    <!--  popup_wrap  -->
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">Template</h1>
      <div class="content_box">
        <!-- content_box -->
        <form id="mailForm">
          <table class="tbl_row">
            <colgroup>
              <col width="75"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>Title</th><!-- title -->
                <td>
                  <input
                    v-model="params.tplNm"
                    type="text"
                    id="tplNm"
                    maxlength="150"
                    @input="inputTextChk('tplNm')"
                  />
                </td>
              </tr>
              <!--
              <tr>
                <th>Remark</th>
                <td>
                  <input
                    v-model="params.rmk"
                    type="text"
                    id="rmk"
                  />
                </td>
              </tr>
              -->
            </tbody>
          </table>
        </form>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <button
          type="button"
          class="button blue lg"
          @click="saveTemplateData()"
        >
          {{ $t('msg.NEWB020P030.004') }}
        </button><!-- 저장 -->
        <button
          type="button"
          class="button gray lg ml5"
          @click="$emit('close')"
        >
          {{ $t('msg.ONIM010P070.008') }}
        </button><!-- 취소 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import { rootComputed } from '@/store/helpers'
import bookingBl from '@/api/rest/trans/bookingBl'

export default {
  name: 'TemplateSavePop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  data: function () {
    return {
      items: [],
      params: {
        tplNm: '',
        rmk: '',
        tplRno: '',
        tplCatCd: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    // console.log('this.auth')
    // console.log(this.auth)
    // console.log('this.memberDetail')
    // console.log(this.memberDetail)
    // console.log('this.selectedProfile')
    // console.log(this.selectedProfile)
    this.init()
  },
  methods: {
    init () {
      console.log('this.parentInfo @@@@ ', this.parentInfo)

      this.params.tplRno = this.parentInfo.tplRno
      this.params.tplCatCd = this.parentInfo.tplCatCd

      if (this.parentInfo.tplCatCd === '01') {
        this.params.tplNm = this.memberDetail.cstCd + '_' + this.parentInfo.pol + '_' + this.parentInfo.pod
      } else {
        this.params.tplNm = this.memberDetail.cstCd + '_SR_' + this.parentInfo.pol + '_' + this.parentInfo.pod
      }
    },
    saveTemplateData () {
      const selector = document.querySelector('#tplNm')
      if (!this.$ekmtcCommon.checkTextByte(selector.value, 150)) {
          this.$ekmtcCommon.showErrorTooltip(selector, '입력한 내용이 너무 많습니다.')
          isOk = false
      }
      bookingBl.insertTemplateSaveData(this.params)
      .then((res) => {
        const result = res.data

        console.log('res @@@@@ ', res)
        console.log('result @@@@@ ', result)

        if (result === 'SUCC') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.NEWB020P030.009'))
          this.$emit('selectFunc')
          this.$emit('close')
        } else if (result === 'FAIL') {
          this.$ekmtcCommon.alertDefault(this.$t('art.CSBK008'))
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070P020.034'))
        }
      })
      .catch(() => {})
    },
    inputTextChk (id) {
      const selector = document.querySelector('#' + id)
      if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
          this.$ekmtcCommon.hideErrorTooltip(selector)
      }
    }
  }
}
</script>
