var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("Template")]),
        _c("div", { staticClass: "content_box" }, [
          _c("form", { attrs: { id: "mailForm" } }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v("Title")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.tplNm,
                          expression: "params.tplNm",
                        },
                      ],
                      attrs: { type: "text", id: "tplNm", maxlength: "150" },
                      domProps: { value: _vm.params.tplNm },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "tplNm", $event.target.value)
                          },
                          function ($event) {
                            return _vm.inputTextChk("tplNm")
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "button",
            {
              staticClass: "button blue lg",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.saveTemplateData()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("msg.NEWB020P030.004")) + " ")]
          ),
          _c(
            "button",
            {
              staticClass: "button gray lg ml5",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("msg.ONIM010P070.008")) + " ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "75" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }